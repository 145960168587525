import PropTypes from "prop-types";
import React from "react";
import {Link} from "react-router-dom";
import {connect, useSelector} from "react-redux";
import {categoriesData, getImageUrl} from "../../pages/data";

import {addToWishlistDispatch} from "../../redux/actions/wishlistActions";
import Breadcrumb from "../Breadcrumb";


const Category = ({category: {id, name, subtitle, visible}}) => {
  if (!visible) return null;
  return (
    <Link to={`/category/${id}`}>
      <div className="list-category">
        <div
          className="list-category__image"
          style={{backgroundImage: `url(${getImageUrl(`/images/categories/${id}`)})`}}>

        </div>
        <div>
          <h2 className="list-category__title">{name}</h2>
          <h2 className="list-category__sub-title">{subtitle}</h2>
        </div>
      </div>
    </Link>
  )
};

const ShopCategories = () => {
  const selectedStore = useSelector(s => s.cartData.selectedStore);
  const categories = useSelector(s => s.config.categories);

  return (
    <div className="categories-page">
      <Breadcrumb pageTitle={`${selectedStore.name} Store`} prevUrl="/" backText='Stores' />
      <div className="list-categories">
        {
          categories.map((c) => <Category category={c} key={c.id}/>)
        }
      </div>
    </div>
  );
}


ShopCategories.propTypes = {
  addToWishlist: PropTypes.func,
  products: PropTypes.array,
  wishlistItems: PropTypes.array
};

const mapStateToProps = state => {
  return {
    wishlistItems: state.wishlistData
  };
};

const mapDispatchToProps = dispatch => {
  return {
    addToWishlist: item => {
      dispatch(addToWishlistDispatch(item));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ShopCategories);
