import {v4 as uuid} from "uuid";
import {
  ADD_TO_CART,
  ADD_BOX_TO_CART,
  REMOVE_FROM_CART,
  SET_SELECTED_STORE,
  DELETE_FROM_CART,
  DELETE_ALL_FROM_CART
} from "../actions/cartActions";

const initState = {
  items: {},
  orderTotal: 0,
  cartHasItems: false,
  selectedStore: null
};

const cartReducer = (state = initState, action) => {

  if (action.type === SET_SELECTED_STORE) {
    return {
      ...state,
      selectedStore: action.payload
    }
  }

  if (action.type === ADD_TO_CART) {
    const id = action.payload.cartId;
    let newState = {...state};

    if (!newState.items) {
      newState.items = {}
    } else {
      newState.items = {
        ...newState.items
      }
    }
    const quantity =  ((state.items[id] && state.items[id].quantity) || 0) + 1;


    let newItem = {};
    if (state.items[id] && state.items[id].boxId) {
      newItem = {
        ...state.items[id],
        quantity,
        linePrice: (state.items[id].packagingCost + state.items[id].itemsCost) * quantity
      }
    } else {
      newItem = {
        productId: id,
        quantity,
        linePrice: action.payload.product.price * quantity
      }
    }

    newState.items[id] = newItem;

    const orderTotal = Object.keys(newState.items).reduce((total, key) => total + newState.items[key].linePrice,0);
    newState.orderTotal = orderTotal || 0;

    newState.cartHasItems = Object.keys(newState.items).length > 0;
    return newState;
  }

  if (action.type === REMOVE_FROM_CART) {
    const id = action.payload.cartId;
    let newState = {...state};

    if (!newState.items) {
      newState.items = {}
    } else {
      newState.items = {
        ...newState.items
      }
    }

    const quantity =  ((state.items[id] && state.items[id].quantity) || 0) - 1;

    let newItem = {};
    if (state.items[id] && state.items[id].boxId) {
      newItem = {
        ...state.items[id],
        quantity,
        linePrice: (state.items[id].packagingCost + state.items[id].itemsCost) * quantity
      }
    } else {
      newItem = {
        productId: id,
        quantity,
        linePrice: action.payload.product.price * quantity
      }
    }

    newState.items[id] = newItem;

    if (newState.items[id].quantity <= 0)
      delete newState.items[id];

    const orderTotal = Object.keys(newState.items).reduce((total, key) => total + newState.items[key].linePrice,0);
    newState.orderTotal = orderTotal || 0;

    newState.cartHasItems = Object.keys(newState.items).length > 0;

    return newState;
  }

  if (action.type === ADD_BOX_TO_CART) {
    const boxId = uuid();
    const id = action.payload.product.id;
    let newState = {...state};

    if (!newState.items) {
      newState.items = {}
    } else {
      newState.items = {
        ...newState.items
      }
    }
    const quantity =  ((state.items[id] && state.items[id].quantity) || 0) + 1;
    const linePrice = (action.payload.packagingCost + action.payload.itemsCost) * quantity;
    newState.items[boxId] = {
      productId: id,
      boxId: boxId,
      quantity,
      linePrice,
      selectedItems: action.payload.selectedItems,
      packagingCost: action.payload.packagingCost,
      itemsCost: action.payload.itemsCost
    };


    const orderTotal = Object.keys(newState.items).reduce((total, key) => total + newState.items[key].linePrice,0);
    newState.orderTotal = orderTotal || 0;

    newState.cartHasItems = Object.keys(newState.items).length > 0;
    return newState;
  }

  if (action.type === DELETE_ALL_FROM_CART) {
    return {
      items: {},
      orderTotal: 0,
      cartHasItems: false,
      selectedStore: state.selectedStore
    }
  }


  // return [...cartItems, action.payload];

  // // for non variant products
  // if (product.variation === undefined) {
  //   const cartItem = cartItems.filter(item => item.id === product.id)[0];
  //   if (cartItem === undefined) {
  //     return [
  //       ...cartItems,
  //       {
  //         ...product,
  //         quantity: product.quantity ? product.quantity : 1,
  //         cartItemId: uuid()
  //       }
  //     ];
  //   } else {
  //     return cartItems.map(item =>
  //       item.cartItemId === cartItem.cartItemId
  //         ? {
  //             ...item,
  //             quantity: product.quantity
  //               ? item.quantity + product.quantity
  //               : item.quantity + 1
  //           }
  //         : item
  //     );
  //   }
  //   // for variant products
  // } else {
  //   const cartItem = cartItems.filter(
  //     item =>
  //       item.id === product.id &&
  //       product.selectedProductColor &&
  //       product.selectedProductColor === item.selectedProductColor &&
  //       (product.cartItemId ? product.cartItemId === item.cartItemId : true)
  //   )[0];
  //
  //   if (cartItem === undefined) {
  //     return [
  //       ...cartItems,
  //       {
  //         ...product,
  //         quantity: product.quantity ? product.quantity : 1,
  //         cartItemId: uuid()
  //       }
  //     ];
  //   } else if (
  //     cartItem !== undefined &&
  //     cartItem.selectedProductColor !== product.selectedProductColor
  //   ) {
  //     return [
  //       ...cartItems,
  //       {
  //         ...product,
  //         quantity: product.quantity ? product.quantity : 1,
  //         cartItemId: uuid()
  //       }
  //     ];
  //   } else {
  //     return cartItems.map(item =>
  //       item.cartItemId === cartItem.cartItemId
  //         ? {
  //             ...item,
  //             quantity: product.quantity
  //               ? item.quantity + product.quantity
  //               : item.quantity + 1,
  //             selectedProductColor: product.selectedProductColor
  //           }
  //         : item
  //     );
  //   }
  // }

  // if (action.type === DECREASE_QUANTITY) {
  //   if (product.quantity === 1) {
  //     const remainingItems = (cartItems, product) =>
  //       cartItems.filter(
  //         cartItem => cartItem.cartItemId !== product.cartItemId
  //       );
  //     return remainingItems(cartItems, product);
  //   } else {
  //     return cartItems.map(item =>
  //       item.cartItemId === product.cartItemId
  //         ? { ...item, quantity: item.quantity - 1 }
  //         : item
  //     );
  //   }
  // }
  //
  // if (action.type === DELETE_FROM_CART) {
  //   const remainingItems = (cartItems, product) =>
  //     cartItems.filter(cartItem => cartItem.cartItemId !== product.cartItemId);
  //   return remainingItems(cartItems, product);
  // }
  //
  // if (action.type === DELETE_ALL_FROM_CART) {
  //   return cartItems.filter(item => {
  //     return false;
  //   });
  // }

  return state;
};

export default cartReducer;
