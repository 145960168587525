const getRootUrl = (stage) => {
  if (stage === "dev") return "https://apporders-api-dev.sugarlicious.mobi";
  else if (stage === "prod") return "https://apporders-api-prod.sugarlicious.mobi";

  // Default
  return "https://apporders-api-dev.sugarlicious.mobi";
};



export const STAGE = process.env.REACT_APP_STAGE || 'dev';
export const API_ROOT_URL = getRootUrl(STAGE);
