export const categoriesData = [
  {
    "id": 1,
    "title": "Ice Cream Macarons",
    "subtitle": "SA's first and favourite",
    "image": "/assets/img/categories/ice_cream_macarons.jpeg"
  },
  {
    "id": 2,
    "title": "Ganache Macarons",
    "subtitle": "Anytime treats",
    "image": "/assets/img/categories/ganache_macarons.jpeg"
  },
  {
    "id": 3,
    "title": "Pillow Cakes",
    "subtitle": "Delicious",
    "image": "/assets/img/categories/pillow_cakes.jpeg"
  },
  {
    "id": 4,
    "title": "Beverages",
    "subtitle": "Frappés and Hot Drinks",
    "image": "/assets/img/categories/beverages.jpg"
  }
];

export const productsData = [
  {
    id: 1,
    categoryId: 1,
    isPack: true,
    name: "6 x Ice Cream Macarons",
    shortDescription: "Biodegradable box packed with complimentary food grade dry ice pellets",
    description: "The On-The-Go Box will give your Ice Cream Macarons approximately 20-30 minutes of travel time. Please store in the coldest part of your freezer immediately. Packed with complimentary food grade dry ice pellets.",
    packCategory: 1,
    fixedPrice: false,
    packCost: 15.00,
    image: "/assets/img/products/1.jpg",
    packSize: 6
  },
  {
    id: 2,
    categoryId: 1,
    isPack: true,
    name: "20 x Ice Cream Macarons",
    shortDescription: "Biodegradable box packed with complimentary food grade dry ice pellets",
    description: "The On-The-Go Box will give your Ice Cream Macarons approximately 20-30 minutes of travel time. Please store in the coldest part of your freezer immediately. Packed with complimentary food grade dry ice pellets.",
    packCategory: 1,
    fixedPrice: false,
    packCost: 20.00,
    image: "/assets/img/products/2.jpg",
    packSize: 20
  },
  {
    id: 3,
    categoryId: 1,
    isPack: false,
    name: "Burfee",
    shortDescription: "Burfee, Almond, Rose & Cardamom",
    description: 'This is by far our most iconic and popular Ice Cream Macaron. It is an Indian inspired flavour made with the real Burfee Indian sweetmeat/dessert which is infused into the ice cream & topped with colourful almond slivers. Burfee contains distinct Rose & Cardamom notes and is by far our creamiest ice cream. It is a real East meets West taste experience.',
    price: 20.00,
    image: "/assets/img/products/3.jpg"
  },
  {
    id: 5,
    categoryId: 1,
    isPack: false,
    name: "Bombay Crush",
    shortDescription: "Falooda, Basil Seeds & China Grass Jelly",
    description: 'The Bombay Crush ice cream Macaron is another Indian inspired flavour based on the Bombay Crush or Falooda Milkshake beverage. It contains actual ingredients used in the beverage, which is sweet basil seeds (sabja seeds) and grated china grass jelly. It is a creamy and texture based\n' +
    'flavour.',
    price: 20.00,
    image: "/assets/img/products/5.jpg"
  },
  {
    id: 6,
    categoryId: 1,
    name: "Chocnut Delux",
    shortDescription: 'Vanilla Ice Cream, Premium Belgian Chocolate & Roasted Peanuts',
    description: 'Our most popular in the Signature Collection – it’s a classic Vanilla ice cream macaron, fully dipped in premium Belgian chocolate & roasted peanuts.',
    price: 30.00,
    image: "/assets/img/products/6.jpg"
  },
  {
    id: 4,
    categoryId: 2,
    selectionCategoryId: 5,
    isPack: true,
    name: "10 x Ganache Macarons",
    shortDescription: "Our Classic Ganache Macarons are filled with a variety of flavoured chocolate ganache.",
    description: "Our Classic Ganache Macarons are filled with a variety of flavoured chocolate ganache.",
    fixedPrice: true,
    packCost: 0,
    price: 130.00,
    image: "/assets/img/products/4.webp",
    packSize: 10
  },
  {
    id: "bounty",
    categoryId: 5,
    name: "Bounty",
    shortDescription: "Classic Macaron filled with Bounty flavoured chocolate ganache",
    description: "Classic Macaron filled with Bounty flavoured chocolate ganache",
    price: 13,
    image: "/assets/img/products/bounty.jpg"
  },
  {
    id: "gm-strawberry",
    categoryId: 5,
    name: "Strawberry",
    shortDescription: "Classic Macaron filled with Strawberry flavoured chocolate ganache",
    description: "Classic Macaron filled with Strawberry flavoured chocolate ganache",
    price: 13,
    image: "/assets/img/products/gm-strawberry.jpg"
  },
  {
    id: "pillow-bubblegum",
    categoryId: 3,
    name: "Bubblegum",
    shortDescription: "Flavourful layers of bubblegum mousse and sponge cake coated in chocolate.",
    description: "Flavourful layers of bubblegum mousse and sponge cake coated in chocolate.",
    price: 30,
    image: "/assets/img/products/pillow-bubblegum.jpg"
  },
  {
    id: "frappe-strawberry",
    categoryId: 4,
    name: "Strawberry Frappé",
    shortDescription: "Strawberry flavour blended with milk & ice",
    description: "A strawberry flavoured frappé blended with milk & ice into a smooth creamy drink. Frappé, pronounced frap-pay, is a flavoured iced beverage that has been shaken, blended or beaten to produce a tasty, foamy and refreshing drink.",
    price: 45,
    image: "/assets/img/products/frappe-strawberry.jpg"
  }
];

export const normalize = (data, key="id", pluck=false) => {
  let ret = {};
  data.forEach((item) => {
    if(item[key]) {
      if (pluck)
        ret[item[key]] = pluck(item, key);
      else
        ret[item[key]] = item;
    }
  });
  return ret;
};

function pluck(obj, propX) {
  let ret = {};
  for(let prop in obj) {
    if(obj.hasOwnProperty(prop)) {
      if(prop === propX) { continue; }
      ret[prop] = obj[prop];
    }
  }
  return ret;
}



export const productById = normalize(productsData);
export const productByCategory = normalize(productsData, "categoryId");
export const categoryById = normalize(categoriesData);

export const packProductsForCategory =  (categoryId) => productsData.filter((p) => p.isPack && p.categoryId.toString() === categoryId.toString());
export const singleProductsForCategory =  (categoryId) => productsData.filter((p) => !p.isPack && p.categoryId.toString() === categoryId.toString());

export const getImageUrl = (url) => {
  if (url.includes('http')) return url;
  return `${process.env.PUBLIC_URL}${url}`
}
