import {apiRequest, API_GET, API_POST} from "./api/actions";
import {
  FETCH_CONFIG, setConfig
} from "../actions/config";


const config = ({dispatch, getState}) => next => action => {

  next(action);

  if (!([FETCH_CONFIG].includes(action.type)))
    return;

  switch (action.type) {
    case FETCH_CONFIG:
      // next(setConfigLoading(true));
      next(
        apiRequest({
          path: "config",
          method: API_GET,
          success: setConfig,
        })
      );
      break;
    default:
      break;
  }

};

export default config;
