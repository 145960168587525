const initState = new Date();

const timeReducer = (state = initState, action) => {

  if (action.type === 'SET_INIT_TIME') {
    return action.payload;
  }
  return state;
};

export default timeReducer;
