import {
  SET_CONFIG
} from "../actions/config";

export const normalize = (data, key="id", pluck=false) => {
  let ret = {};
  data.forEach((item) => {
    if(item[key]) {
      if (pluck)
        ret[item[key]] = pluck(item, key);
      else
        ret[item[key]] = item;
    }
  });
  return ret;
};

function pluck(obj, propX) {
  let ret = {};
  for(let prop in obj) {
    if(obj.hasOwnProperty(prop)) {
      if(prop === propX) { continue; }
      ret[prop] = obj[prop];
    }
  }
  return ret;
}


const getAllProductsById = (categories) => {
  let products = {};

  if (!categories) return products;

  categories.forEach((category) => {
    category.products.forEach((product) => {
      products[product.id] = product;
    })
  });

  return products;
};

const initState = {
  categories: [],
  stores: [],
  categoryById: {},
  productById: {}
};

const config = (state = initState, action) => {

  if (action.type === SET_CONFIG) {
    return {
      ...state,
      categories: action.payload.categories,
      stores: action.payload.stores,
      categoryById: normalize(action.payload.categories),
      productById: getAllProductsById(action.payload.categories)
    }
  }

  return state;
};

export default config;
