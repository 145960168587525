import React from "react";
import {useSelector} from 'react-redux';

import { NavLink } from "react-router-dom";
import { ReactSVG } from "react-svg";

const Footer = () => {

  const cartHasItems = useSelector(s => s.cartData.cartHasItems);

  return (
    <footer>
      <div className="footer-nav-wrapper">
        <NavLink
          to={process.env.PUBLIC_URL + "/category"}
          activeClassName="footer-active"
          className="footer-nav-single"
        >
          <div className="menu-wrapper">
            <ReactSVG
              src={process.env.PUBLIC_URL + "/assets/img/icons/macaron.svg"}
            />
            <span>Products</span>
          </div>
        </NavLink>
        <NavLink
          to={process.env.PUBLIC_URL + "/cart"}
          activeClassName="footer-active"
          className="footer-nav-single"
        >
          <div className="menu-wrapper">
            <ReactSVG
              className={cartHasItems ? 'cart-has-items' : ''}
              src={process.env.PUBLIC_URL + "/assets/img/icons/cart.svg"}
            />
            <span>Cart</span>
          </div>
        </NavLink>
        <NavLink
          to={process.env.PUBLIC_URL + "/contact"}
          activeClassName="footer-active"
          className="footer-nav-single"
        >
          <div className="menu-wrapper">
            <ReactSVG
              src={process.env.PUBLIC_URL + "/assets/img/icons/chat.svg"}
            />
            <span>Contact</span>
          </div>
        </NavLink>
      </div>
    </footer>
  );
};

export default Footer;
