import {apiRequest, API_GET, API_POST} from "./api/actions";
import {push} from "./router";
import {
  PLACE_ORDER, ORDER_PLACED, orderPlaced
} from "../actions/orders";
import {DELETE_ALL_FROM_CART} from "../actions/cartActions";


const orders = ({dispatch, getState}) => next => action => {

  next(action);

  if (!([PLACE_ORDER, ORDER_PLACED].includes(action.type)))
    return;

  switch (action.type) {
    case PLACE_ORDER:
      next(
        apiRequest({
          path: "order",
          method: API_POST,
          data: action.payload,
          success: orderPlaced
        })
      );
      break;

    case ORDER_PLACED:
      dispatch({type: DELETE_ALL_FROM_CART});
      break;
    default:
      break;
  }

};

export default orders;
