import React from "react";
import ReactDOM from "react-dom";
import { createStore, applyMiddleware } from "redux";
import {createBrowserHistory} from "history";

import thunk from "redux-thunk";
import { save, load , clear } from "redux-localstorage-simple";
import { Provider } from "react-redux";

import rootReducer from "./redux/reducers/rootReducer";
import api from './redux/middleware/api';
import routerMiddleware from './redux/middleware/router';
import config from './redux/middleware/config';
import orders from './redux/middleware/orders';
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { composeWithDevTools } from "redux-devtools-extension";

const localStorage = load({states: ["timestamp"]});

let needsClear = true;

function sameDay(d1, d2) {
  return d1.getFullYear() === d2.getFullYear() &&
    d1.getMonth() === d2.getMonth() &&
    d1.getDate() === d2.getDate();
}

if (localStorage.timestamp) {
  // const pastTime = new Date(localStorage.timestamp).getTime();
  // const ttl = 1000*60*60*2;

  // needsClear = (new Date().getTime() - pastTime) > ttl;

  needsClear = !sameDay(new Date(localStorage.timestamp), new Date());
  console.log("cart set up at ", localStorage.timestamp);
}

if (needsClear) {
  console.log("Clearing cart - ttl expired");
  clear();
} else {
  console.log("Keeping cart");
}

export const history = createBrowserHistory();

const featureMiddleware = [config, orders];
const coreMiddleware = [thunk, routerMiddleware(history), save({states: ["timestamp", "cartData"]}), api];


const store = createStore(
  rootReducer,
  load({states: ["timestamp", "cartData"]}),
  composeWithDevTools(applyMiddleware(...featureMiddleware, ...coreMiddleware))
);

if (needsClear) {
  store.dispatch({type: 'SET_INIT_TIME', payload: new Date()})
}



ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA



serviceWorker.register({
  onSuccess: (registration) => {


  }
});
