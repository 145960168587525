import productReducer from "./productReducer";
import cartReducer from "./cartReducer";
import timeReducer from "./timeReducer";
import config from "./configReducer";
import orderReducer from "./orderReducer";
import { combineReducers } from "redux";

const rootReducer = combineReducers({
  timestamp: timeReducer,
  productData: productReducer,
  cartData: cartReducer,
  config,
  orderReducer
});

export default rootReducer;
